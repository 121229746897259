import React from 'react'
import { Col, Row } from 'react-bootstrap'

import '../Styles/Home.scss';

export default function Home() {
    return (
        
            <div className='home heading' fluid="md">
                <Row>
                <Col className='about'>
                        <h1>My Name Is</h1>                    
                        <h1>Thomas Bach-Nilsen</h1>
                            <p className='info'>I'm a Front-End Developer with a lot of skills from different work fields, such as logistics, economics, purchasing and pricing.  </p>
                    </Col>
                </Row>
                <Row>
                    <Col className='skills'>
                        <h1>Skills</h1>
                    <ol className='skills-list'>
                        <li className='skills-list-item'>
                            <h2>Front-End</h2>
                            <p>HTML, CSS, SASS, NPM, React.JS, Next.JS, Bootstrap, StyledComponents, MaterialUI</p>
                        </li>
                        <li className='skills-list-item'>
                            <h2>Back-End</h2>
                            <p>WP, Node.JS, ApolloServer, GraphQL, Rest-API, Axios</p>
                        </li>
                        <li className='skills-list-item'>
                            <h2>Code languages</h2>
                            <p>Javascript, Typescript</p>
                        </li>                        
                    </ol>
                    </Col>
                </Row>
            </div>
    

  )
}

