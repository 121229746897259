import React from 'react'
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import SchoolIcon from '@mui/icons-material/School';
import WorkIcon from '@mui/icons-material/Work';

import 'react-vertical-timeline-component/style.min.css';

import '../Styles/Experience.scss';

export default function Experience() {
    return (
        <div className='experience heading' fluid="md">
            <VerticalTimeline lineColor='#22A2F2'>
                <VerticalTimelineElement
                    className='vertical-timeline-element--education'
                    iconStyle={{backgroundColor: '#22A2F2'}}
                    icon={<SchoolIcon />}>
                    
                    <h3 className='vertical-timeline-element-title'>
                        <a href='https://www.noroff.no/studier/nettstudier/oppstart-august'>Noroff</a>
                    </h3>
                    <p className='vertical-timeline-element-title'>Front-End Developer, webstudies</p>
                    <p className='date'>2019 - 2022</p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className='vertical-timeline-element--work'
                    iconStyle={{backgroundColor: '#1A2E40'}}
                    icon={<WorkIcon />}>
            <h3 className='vertical-timeline-element-title'>Feds</h3>
                    <p className='vertical-timeline-element-title'>Front-End Developer</p>
                    <p className='date'>2023 - </p>
                </VerticalTimelineElement>
            </VerticalTimeline>
        </div>
    )
}