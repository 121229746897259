import React from 'react'
import { Col, Row } from 'react-bootstrap'

import { MdOutlinePhoneIphone } from 'react-icons/md';
import { MdOutlineMailOutline } from 'react-icons/md';



import '../Styles/Contact.scss';

export default function Contact() {
    return (
        
            <div className='home heading' fluid="md">
                <Row>
                <Col className='contact'>
                        <h1>Contact Me</h1>                    
                    <p>If you want to know if I can help, contact me for more info</p>
                    <div className='contactInfo'>
                    <a href="tel:95255336"><MdOutlinePhoneIphone />95255336</a>
                    <a href="mailto:post@feds.no?subject=Mail from our Website"><MdOutlineMailOutline /> Email me from here!</a>
                    </div>
                    </Col>
                </Row>
            </div>
    

  )
}

