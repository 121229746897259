import React from 'react'
import Github from '@mui/icons-material/GitHub';

import '../Styles/Footer.scss';

export default function Footer() {
  return (
      <footer>
          <div className='socialMedia'>
        <a href='https://github.com/FEDS74' target='_blank' rel='noopener noreferrer'>
          <Github />
        </a>
          </div>
          <p>&copy; 2022 feds.no</p>
    </footer>
  )
}
