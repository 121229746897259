import project_exam_2 from '../assets/Project-exam 2.png';
import js_frameworks_ca from '../assets/js-frameworks-ca screenshot.png';
import semester_project_2 from '../assets/Semester Project 2 screenshot.png';


export const WorkList = [
    {
        title: 'Semester Project 2',
        description: 'The project is a site to shop for dog toys and other products, including login page for adding, removing and editing products for an admin account. It features an home page with a hero image and featured products as well as a footer for information about the Dog Shop.',
        image: semester_project_2,
        link: 'https://github.com/FEDS74/Semester_Project_2',
    },    
    {
        title: 'JavaScript Frameworks CA',
        description: 'The project is a site working with an API for Rick and Morty. The home page displayes a navbar with logo, home, contact, characters list and login. You can check out the first 20 characters and se their detail info. The contact form is to get feedback and the login form is to use forms with react-hook-form.',
        image: js_frameworks_ca,
        link: 'https://github.com/Noroff-FEU-Assignments/js-frameworks-course-assignment-FEDS74',        
    },  
    {
        title: 'Project Exam 2',
        description: 'The project is a site for a real site for Lillestrøm Gestalterapi. The site is for users of all ages over 16 years old, to be able to access their inner best. Site consists of several pages that guides users to either blog or info about gestalt or the site owner.',
        image: project_exam_2,
        link: 'https://github.com/Noroff-FEU-Assignments/project-exam-2-FEDS74',        
    },            
];


