import React from 'react'
import { Card, Button } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";

export default function WorkCard({ title, image, id }) {
   const navigate = useNavigate();
  return (
    <div className='work-card'>
        <Card fluid="md">
            <Card.Img variant="top" src={image} />
            <Card.Body>
                  <Card.Title>{ title }</Card.Title>
                    <Button className='btn' variant="primary" onClick={() => {
                      navigate("/works/" + id);
          }}>Go to details</Button>
            </Card.Body>
        </Card>
    </div>
  )
}


