import React from 'react';
import { Container } from 'react-bootstrap';
import WorkCard from '../components/WorkCard';
import { WorkList } from '../helpers/WorkList';

import '../Styles/Works.scss';

export default function Works() {
  return (
        <div className='works heading'>
      <h1> My Personal Work</h1>
      <Container className='work-list'>
      {WorkList.map((work, idx) => {
        return (
          <WorkCard id={idx} title={work.title} description={work.description} image={work.image} link={work.link} />
          );
        })}
      </Container>   
      </div>
  );  
}
