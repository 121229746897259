import React from "react";
import { Container, Nav, Navbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import '../components/Navigation';
import '../Styles/Navigation.scss';

export default function Navigation() {
    return (
        <div>
            <Navbar
                className="navigation py-4"
                collapseOnSelect="collapseOnSelect"
                expand="lg">
                <Container>
                    <Navbar.Brand href="/">FEDS</Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav"><MenuIcon /></Navbar.Toggle>
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="ms-auto links">
                            <Link to="/">Home</Link>
                            <Link to="/works">Work</Link>
                            <Link to="/experience">Experience</Link>
                            <Link to="/contact">Contact</Link>                            
                        </Nav> 
                    </Navbar.Collapse>
                    </Container>
            </Navbar>
        </div>
    )
}
