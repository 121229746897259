import React from 'react';
import { useParams } from 'react-router-dom';
import { WorkList } from '../helpers/WorkList';

import '../Styles/WorkDetail.scss';

export default function WorkDetail() {
  
    const { id } = useParams();
    const work = WorkList[id];

  return (
    <>
      <div className='workDetails heading '>
      <h1> {work.title}</h1>
      <h5 classname='description'>{work.description}</h5>
      <img src={work.image} alt='image of project screenshot' />    
      <a href={work.link} target='_blank' rel='noopener noreferrer'>Go to my github repository</a>
    </div>      
    </>
  )
}

