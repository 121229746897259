import * as React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.scss";
import Home from './pages/Home';
import Works from './pages/Works';
import Experience from './pages/Experience';
import Navigation from './components/Navigation';
import Footer from "./pages/Footer";
import WorkDetail from "./pages/WorkDetail";
import Contact from "./pages/Contact";




function App() {
  return (

    <div className="App">
      <Router>
        <Navigation />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/works' element={<Works />} />
          <Route path="/works/:id" element={<WorkDetail />} />          
          <Route path='/experience' element={<Experience />} />
          <Route path='/contact' element={<Contact />} />        
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
